import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Block from '../Widgets/Block';
import {useOutlet} from 'reconnect.js';
import {message, Result, Collapse} from 'antd';
import {PROMOTION_TYPE} from '../dictionary';
import {errorHandler} from '../errors';
import Button from '../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import IconButton from '../Widgets/IconButton';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import Hint from '../Widgets/Hint';
import PromotionDialog from '../Dialogs/PromotionDialog';
const appConfig = require('../data.json');

function parse(str, defaultValue = []) {
  try {
    return JSON.parse(str);
  } catch (err) {
    console.warn(err);
    return defaultValue;
  }
}

export default function PromotionLogisticFee(props) {
  const {pageContext} = props;
  const {type} = pageContext;
  const [actions] = useOutlet('actions');
  const [record, setRecord] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [products, setProducts] = useState([]);

  const getData = useCallback(async () => {
    actions.setLoading(true);
    try {
      let resp = await actions.getPromotionFee({});
      setRecord(resp);
    } catch (err) {
      console.warn(err);
      message.error('無法取得最新優惠，可能尚未建立');
    }
    actions.setLoading(false);
  }, []);

  const getProducts = useCallback(async () => {
    try {
      let resp = await actions.getProducts({
        no_page: true,
        ordering: '-updated',
        is_on_shelf: true,
      });
      setProducts(resp);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    getData();
    getProducts();
  }, [getData, getProducts]);

  return (
    <Wrapper>
      <Block>
        <div className="row" style={{margin: 0}}>
          <h2>目前優惠設定</h2>
          <div style={{flex: 1}} />

          <Button
            type="primary"
            onClick={() => getData()}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
        <div className="divider" />
        <div>
          {!record ? (
            <Result
              title="無優惠顯示"
              subTitle="此會員群組尚未建立此優惠，請點選新增。"
              status="info"
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    setOpenDialog(true);
                    // TODO: create
                  }}>
                  新增
                </Button>
              }
            />
          ) : type === PROMOTION_TYPE.LOGISTIC_FEE && record ? (
            <Collapse defaultActiveKey={0}>
              <Collapse.Panel
                header={`# ${record.id}`}
                extra={
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDialog(true);
                    }}
                    hoverColor={appConfig.colors.sub}>
                    <Edit />
                  </IconButton>
                }>
                <div className="row">
                  <h4>優惠種類</h4>
                  <div>免運</div>
                </div>
                <div className="row">
                  <h4>優惠名稱</h4>
                  <div>{record.name}</div>
                </div>
                <div className="row">
                  <h4>優惠</h4>
                  <div>商品金額達 {record.threshold} 元，可享折扣優惠 </div>
                </div>
                <div className="row">
                  <h4>已使用次數</h4>
                  <div>{parse(record.users, [])?.length || 0}</div>
                </div>
                <div className="row">
                  <h4> 全站使用上限</h4>
                  <div>{record.usage}</div>
                </div>
                <div className="row">
                  <h4>排除商品</h4>
                  <div>{parse(record.exclude_product, []).join(', ')}</div>
                  <Hint type="info">
                    若購物車存在排除商品則不適用此免運優惠
                  </Hint>
                </div>
                <div className="row">
                  <h4>使用期限</h4>
                  <div>{record.expire_date}</div>
                  <ExpireHint date={record.expire_date} />
                </div>
              </Collapse.Panel>
            </Collapse>
          ) : null}
        </div>
      </Block>
      <PromotionDialog
        title={'修改優惠'}
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        type={type}
        record={record}
        onUpdate={() => getData()}
        products={products}
      />
    </Wrapper>
  );
}

function ExpireHint({date}) {
  const _date = new Date(date);
  _date.setHours(23, 59, 59, 999);
  if (_date < new Date()) {
    return <Hint type="warning">此優惠已過期</Hint>;
  }
  return null;
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
